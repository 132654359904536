import React, { useContext } from 'react';
import styled from 'styled-components';
import { Metadata } from '@latitude/metadata';
import { FeatureTiles } from '@latitude/feature-tiles';
import Layout from '../components/layout';
import Lframe from '../components/Lframe/Lframe';
import HeroBranded from '../components/HeroBranded/HeroBranded';
import PageData from '../data/pages/calculators-and-tools.json';
import { COLOR } from '../utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { PageContext } from '@/context/PageContext';

const CalculatorsAndToolsPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  console.log(contentfulPageData);
  
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical="/calculators-and-tools/"
        />

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <>
            <Lframe calculators style={{ top: 0 }} />
            <HeroBranded
              css={`
                background: ${COLOR.GREY6};
              `}
              title={<>Calculators and tools</>}
              text="Let's help you get started!"
            />
          </>
        )}
        
        <StyledFeatureTiles
          id="calculators-and-tools"
          tiles={PageData.content.calculators}
        />
      </main>
    </Layout>
  )
};

const StyledFeatureTiles = styled(FeatureTiles)`
  position: relative;
  z-index: 10;
`;

export default CalculatorsAndToolsPage;
